import * as React from "react";
import Cotizacion from "../common/Cotizacion";
import { ICotizacion } from "../types/Cotizacion.types";

export const defaultData: ICotizacion = {
  _id: "",
  aprobado: null,
  proforma: "",
  costoBrutoUnitario: 0,
  costoTotal: 0,
  margenBruto: 0,
  precioUnitario: 2.9,
  utilidad: 0,
  tipoDeProducto: "MONOCAPA",
  tipoDeVariable: "NINGUNO",
  createdAt: new Date(),
  updatedAt: new Date(),
  empleado: {
    _id: "",
    first: "",
    last: "",
    email: "",
    cellphone: "",
  },
  aprobadaPor: {
    _id: "",
    first: "",
    last: "",
    email: "",
    cellphone: "",
  },
  lote: 250,
  cliente: null,
  producto: "",
  comision: 2,
  diasDeCredito: 120,
  refileGeneral: 10,
  anchoPt: 10,
  numBandas: 2,
  numColores: 8,
  frecuencia: 305,
  repeticiones: 2,
  fondeado: false,
  manga: false,
  clisses: true,
  tipoDeClisses: "COMPLETO",
  clissesCm2: 0,
  selladora: {
    _id: "",
    nombre: "NO ES BOLSA",
    tarifa: 0,
    tipoDeBolsas: "",
  },
  impresora: {
    _id: "",
    nombre: "",
    tarifa: 0,
    descontinuada: false,
    limiteImpresion: 0,
    numColores: 0,
    tazaPreparacion: 1,
    velocidad: 0,
    cilindros: "",
  },
  zipper: {
    _id: "",
    nombre: "",
    precio: 0,
  },
  valvula: {
    _id: "",
    nombre: "",
    precio: 0,
  },
  velocidad: 50,
  tipoDeBolsa: "Sellado T",
  b22: false,
  exporte: false,
  materiasPrimas: [],
  horasHombre: [],
  mac: false,
  selladoExterno: {
    merma: 0.05,
    sellado: 0.4,
    transporte: 0.01,
  },
  sensibilidad: [],
  servicios: [],
  codigo: "",
  version: 0,
  eliminada: null,
  deltaAprobacion: 0,
  solicitud: null,
  observaciones: "",
  estandar: "",
  dobleImpresion: false,
  muestra: false,
};

interface IContex {
  data: ICotizacion | null;
  cotizacion: Cotizacion;
  setData: (data: any) => void | null;
  setSensibilidad: any;
  approve?: any;
  talleres: any[];
}
export const CotizacionContext = React.createContext<IContex>({
  cotizacion: new Cotizacion(defaultData, []),
  data: null,
  setData: (data) => null,
  setSensibilidad: () => null,
  talleres: [],
});
