import React, { Suspense } from 'react';
import { ApolloProvider } from '@apollo/react-hooks';
import { ApolloClient } from 'apollo-boost';
import { createHttpLink } from 'apollo-link-http';
import { setContext } from 'apollo-link-context';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";

//  Utils
import { GEN_ROUTES, ADMIN_ROUTES } from './routes';
import { UserContext } from './contexts/UserContext';
import './App.css';
import { IClientUser } from './types/User.types';

// Components
import Login from './screens/login';
import { USER_QUERIES } from './graphql/queries/user.queries';
import NotFound from './screens/not-found';
import ChangePassword from './screens/change-password';
import Toast from './components/singletons/Toast';
import { API_URL } from './constants/setup';
import LoadingScreen from './components/_general/LoadingScreen';
import { AUTH_TOKEN } from './constants/auth';
import { UserRole } from './constants/userRoles';
import ReclamosScreen from './screens/reclamos';

const httpLink = createHttpLink({ uri: `${API_URL}/graphql` });

const authLink = setContext(async (_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem(AUTH_TOKEN) || sessionStorage.getItem(AUTH_TOKEN) || '';
  // return the headers to the context so httpLink can read them
  return { headers: { ...headers, authorization: token ? `Bearer ${token}` : "", } }
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  //@ts-ignore
  cache: new InMemoryCache({ addTypename: false, dataIdFromObject: object => object._id || object.id }),
});

function App() {
  const routes = GEN_ROUTES.concat(ADMIN_ROUTES);

  const [user, setUser] = React.useState<IClientUser | null>(null);
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    (async () => {
      try {
        let token = localStorage.getItem(AUTH_TOKEN);
        if (token) {
          sessionStorage.setItem(AUTH_TOKEN, token);
          const response = await client.query({ query: USER_QUERIES.GET_LOGGED_IN_USER });
          const { data } = response;
          if (data.getLoggedInUser) setUser(data.getLoggedInUser);
        }
        setLoading(false);
      } catch (err) {
        console.log('error getting user', err);
        localStorage.setItem(AUTH_TOKEN, '');
        setLoading(false);
      }
    })();
  }, []);

  if (loading) return <LoadingScreen />;

  return (
    <Suspense fallback={<LoadingScreen />}>
      <ApolloProvider client={client}>
        <UserContext.Provider value={{ me: user, setUser }}>
          <Router>
            <Switch>
              <Route children={<Login />} path='/login' exact={true} />
              <Route children={<ChangePassword />} path='/restablecer-contrasena' exact={true} />
              <Route children={<ReclamosScreen />} path='/reclamos' exact={true} />
              {routes.map((r, i) => <PrivateRoute path={r.path} key={i} exact={r.exact} children={<r.main />} me={user} role={r.role} />)}
              <Route path="*">
                <NotFound />
              </Route>
            </Switch>
          </Router>
          <Toast />
        </UserContext.Provider>
      </ApolloProvider>
    </Suspense>
  );
}

const PrivateRoute: React.FC<any> = (props) => {
  const { children, me, role, ...rest } = props;


  const redirect = (loc: any) => <Redirect to={{ pathname: "/login", state: { from: loc } }} />;
  if (me && !role.includes(me.role)) {
    if (me.role === UserRole.PRODUCTION) {
      return <Redirect to={{ pathname: "/estandares" }} />
    } else {
      return <Redirect to={{ pathname: "/" }} />
    }
  }
  return (
    <Route
      {...rest}
      render={({ location }) => me ? (children) : (redirect(location))}
    />
  );
}

export default App;
