export const UserRole = {
  ADMIN: "ADMIN",
  MANAGER: "MANAGER",
  PRODUCTION: "PRODUCTION",
  EMPLOYEE: "EMPLOYEE",
  COST: "COST",
  READ_ONLY: "READ_ONLY",
};

export const canAddMuestraRoles = [
  UserRole.ADMIN,
  UserRole.MANAGER,
  UserRole.COST,
];
