export function ccyFormat(num: number) {
    if (!num) return `$ 0.00`;
    return `$ ${comas(num, 2, '.', ',')}`;
}

function comas(number, decimals, dec_point, thousands_sep) {
    dec_point = typeof dec_point !== 'undefined' ? dec_point : '.';
    thousands_sep = typeof thousands_sep !== 'undefined' ? thousands_sep : ',';

    var parts = number.toFixed(decimals).split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, thousands_sep);

    return parts.join(dec_point);
}

export function toDecimal(num: number, places: number) {
    if (!num) return 0;
    return `${comas(num, places, '.', ',')}`;
}

export function displayBool(val: boolean) {
    return val ? 'Sí' : 'No';
}

export function toPercent(val: number) {
    return toDecimal(val * 100, 2) + '%';
}