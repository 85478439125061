import { min } from "underscore";
import {
  ICotizacion,
  IHorasHombre,
  IMateriaPrimaEmbedded,
  ISensibilidad,
} from "../types/Cotizacion.types";
import { IEstandarVersion } from "../types/Estandar.types";
import { IImpresora } from "../types/Impresora.types";
import { IMateriaPrima } from "../types/MateriaPrima.types";
import { IScrap } from "../types/Scraps.types";
import db from "./database";

export function round(value: any, decimals: any): number {
  //@ts-ignore
  return Number(Math.round(value + "e" + decimals) + "e-" + decimals);
}

interface ILoteRow {
  mp: number;
  tinta: number;
  adh: number;
  conv: number;
  ext: number;
  precio: number;
  costoUnidad: number;
  clisses: number;
  financiamiento: number;
  flete: number;
  exp: number;
  cajas: number;
  pines: number;
  zipper: number;
  valvula: number;
  otros: number;
  margen: number;
  scrap: number;
  precioMillar: number;
  comV: number;
  tucos: number;
  cintaDeEmbalaje: number;
  gastoAdministrativo: number;
}

const roundValue = 12;

class Cotizacion {
  data: any;
  scrap: number = 0;
  cilindro: number = 0;
  anchoIMP: number = 0;
  numBolsas: number = 0;
  totalesMateriaPrima = { gramaje: 0, costo: 0 };
  mtsPorSegundo: number = 0;
  costoClisses: number = 0;
  costoTotal: number = 0;
  costoBrutoUnitario: number = 0;
  utilidad: number = 0;
  margenBruto: number = 0;
  kiloMillar: number = 0;
  aporte: number = 0;
  estructura: string = "";
  scraps: IScrap[] = [];
  rows: any = [];
  estandar: IEstandarVersion | null | undefined;
  talleres: any;
  dobleImpresion: boolean = false;

  loteTable: ILoteRow = {
    mp: 0,
    tinta: 0,
    adh: 0,
    conv: 0,
    ext: 0,
    precio: 0,
    costoUnidad: 0,
    clisses: 0,
    financiamiento: 0,
    flete: 0,
    exp: 0,
    cajas: 0,
    pines: 0,
    zipper: 0,
    valvula: 0,
    otros: 0,
    margen: 0,
    scrap: -1,
    precioMillar: 0,
    comV: 0,
    tucos: 0,
    cintaDeEmbalaje: 0,
    gastoAdministrativo: 0,
  };

  private margenBase: number | undefined;

  constructor(
    d: ICotizacion | IEstandarVersion,
    scraps: IScrap[],
    margenBase?: number,
    lote?: number,
    savedLoteTable?: any,
    talleres?: any
  ) {
    this.data = JSON.parse(JSON.stringify(d));
    if (lote) this.data.lote = lote;
    this.scraps = scraps;
    this.getScrap();
    if (savedLoteTable) {
      this.loteTable = savedLoteTable;
      if (this.loteTable.scrap < 0) {
        this.getScrap();
      } else {
        this.scrap = this.loteTable.scrap;
      }
    }
    this.margenBase = margenBase;
    if (talleres) this.talleres = talleres;
    this.update();
  }

  setTalleres(talleres: any) {
    this.talleres = talleres;
    this.calcularHoraHombre();
  }

  setEstandar(estandar) {
    this.estandar = estandar;
  }

  getScrap = () => {
    if (this.data.aprobadaPor && this.data.aprobadaPor._id) {
      const sensibilidad = this.data.sensibilidad.find(
        (s) => s.lote === this.data.lote
      );
      this.scrap = sensibilidad.scrap;
      this.loteTable.scrap = sensibilidad.scrap;
      return;
    }
    if (this.scraps.length === 0) return;
    let producto = this.scraps.find(
      (s) => s.tipoDeProducto === this.data.tipoDeProducto
    );
    let peso = producto?.pesos.find((p) => p.peso === this.data.lote);
    let scrap =
      (this.data.selladora.nombre === "NO ES BOLSA"
        ? peso?.sinBolsa
        : peso?.conBolsa) || 0;
    this.loteTable.scrap = scrap;
    this.scrap = this.loteTable.scrap;
  };

  update(update?: object) {
    if (update) {
      this.data = Object.assign({}, this.data, update);
      const keys = Object.keys(update);
      if (keys.includes("version")) return;
      if (keys.includes("aprobado")) return;
      if (keys.includes("tipoDeVariable")) this.updateParafina();
      if (keys.includes("tipoDeProducto")) {
        this.updateMateriasPrimas();
        this.data.sensibilidad = this.data.sensibilidad.map((s) => ({
          ...s,
          scrap: -1,
        }));
      }
      if (keys.includes("selladora")) {
        this.data.sensibilidad = this.data.sensibilidad.map((s) => ({
          ...s,
          scrap: -1,
        }));
      }
      if (!keys.includes("sensibilidad")) this.getScrap();
    }
    this.anchoIMP = Math.min(
      this.isManga(this.data.anchoPt) * this.data.numBandas +
        this.data.refileGeneral,
      this.data.impresora?.nombre
        ? this.data.impresora.limiteImpresion
        : Infinity
    );
    this.cilindro = this.data.frecuencia * this.data.repeticiones;
    this.participacion();
    this.calculateKGs();
    this.calcularClisses();
    this.calcularTotalesMateriaPrima();
    this.numeroDeBolsas();
    this.metrosPorSegundo();
    this.calcularHoraHombre();
    this.calcularFilaTabla();
    this.calcularAporte();
    this.crearEstructura();
    this.calcularTotales();
  }

  private updateParafina() {
    if (this.data.tipoDeVariable !== "PARAFINADO") {
      this.data.materiasPrimas.splice(-1);
    }
  }

  private isManga(value) {
    if (this.data.manga) {
      return value * 1.0;
    } else {
      return value;
    }
  }

  private updateMateriasPrimas() {
    this.data.materiasPrimas = [];
  }

  public parseMaterial(
    materia: IMateriaPrima,
    index = 0
  ): IMateriaPrimaEmbedded {
    const option = materia.opciones[index];
    return {
      index,
      _id: materia._id,
      nombre: materia.nombre,
      tipo: materia.tipo,
      extrusion: materia.extrusion,
      micras: option.micras,
      densidad: option.densidad,
      costo: option.costo,
      gramaje: option.gramaje,
      refile: option.refile,
      anchoImprenta: 0,
      costoTotal: 0,
      kg: 0,
      participacion: 0,
      comodin: false,
      scrap: materia.scrap || this.scrap,
    } as IMateriaPrimaEmbedded;
  }

  private crearEstructura() {
    this.estructura = this.data.materiasPrimas
      .filter((p) => p && p.tipo === "LAMINA")
      .map((p) => p.nombre)
      .join(" / ");
  }

  private numeroDeBolsas() {
    if (this.data.selladora.nombre === "NO ES BOLSA")
      return (this.numBolsas = 0);
    let gm2 = this.data.materiasPrimas.reduce(
      (prev, cur) => (prev += cur ? cur.gramaje : 0),
      0
    );
    this.numBolsas = Math.ceil(
      (this.data.lote /
        ((gm2 * this.data.frecuencia * this.isManga(this.data.anchoPt)) /
          1000000)) *
        1000
    );
    this.kiloMillar = round(
      (gm2 * this.data.frecuencia * this.isManga(this.data.anchoPt)) / 1000000,
      roundValue
    );
  }

  private participacion() {
    const total = this.data.materiasPrimas.reduce((prev, cur) => {
      if (cur) {
        if (cur.tipo === "LAMINA") {
          cur.gramaje = round(cur.densidad * cur.micras, roundValue);
        }

        return (prev += cur.gramaje);
      }
      return prev;
    }, 0);

    this.data.materiasPrimas.forEach((m: IMateriaPrimaEmbedded) => {
      if (m) m.participacion = round((m.gramaje || 0) / total, roundValue);
    });
  }

  private calcularClisses() {
    if (!this.data.clisses) return (this.costoClisses = 0);
    const clissesFactor = 0.042;
    switch (this.data.tipoDeClisses) {
      default:
      case "COMPLETO":
        this.costoClisses = round(
          (((this.anchoIMP + 14) *
            (10 + this.data.frecuencia * this.data.repeticiones) *
            this.data.numColores) /
            100) *
            clissesFactor,
          roundValue
        );
        break;
      case "SECTORIZADO":
        this.costoClisses = round(
          this.data.clissesCm2 * clissesFactor,
          roundValue
        );
        break;
    }
  }

  private calcularAporte() {
    const sensibilidad = this.data.sensibilidad.find(
      (s) => s.lote === this.data.lote
    );
    if (!sensibilidad) return 0;
    const { precio, costoUnidad, conv, ext } = sensibilidad;
    this.aporte = round(precio - costoUnidad + conv + ext, roundValue) || 0;
  }

  private calculateKGs() {
    //@ts-ignore
    this.data.materiasPrimas.forEach((m: IMateriaPrimaEmbedded) => {
      if (!m) return;
      let kg = 0;
      let { participacion } = m;
      let scrap = this.data.muestra ? m.scrap : this.scrap;
      switch (m.tipo) {
        case "LAMINA":
          let partOne = participacion * this.data.lote * (1 + scrap);
          let partTwo = (this.data.refileGeneral + m.refile) / 1000;
          let partThree =
            ((partOne * 1000) /
              ((this.isManga(this.data.anchoPt) *
                this.data.numBandas *
                m.gramaje) /
                1000)) *
            (m.gramaje / 1000);
          kg = round(partOne + partTwo * partThree, roundValue);
          this.anchoImprenta(m);
          break;
        case "TINTA":
        case "PARAFINA":
        case "ADHESIVO":
          kg = round(participacion * this.data.lote * (1 + scrap), roundValue);
          break;
        case "SOLVENTE":
          let tinta = this.data.materiasPrimas.find(
            (m) => m && m.tipo === "TINTA"
          );
          if (!tinta) return 0;
          kg = tinta.kg * 3;
          break;
        case "SOLVENTE ADHESIVO":
          let adhesivo = this.data.materiasPrimas.find(
            (m) => m && m.tipo === "ADHESIVO"
          );
          if (!adhesivo) return 0;
          kg = adhesivo.kg * 2;
          break;
        default:
          kg = 0;
          break;
      }
      m.kg = kg;
      m.costoTotal = round(kg * m.costo, roundValue);
    });
  }

  private anchoImprenta(materia: IMateriaPrimaEmbedded) {
    materia.anchoImprenta = this.anchoIMP + materia.refile;
  }

  private calcularTotalesMateriaPrima() {
    let res = this.data.materiasPrimas.reduce(
      (prev, cur) => {
        if (!cur) return prev;
        prev.gramaje += cur.gramaje;
        prev.costo += cur.kg * cur.costo;
        return prev;
      },
      { gramaje: 0, costo: 0 }
    );
    res.costo = round(res.costo, roundValue);
    res.gramaje = round(res.gramaje, roundValue);
    this.totalesMateriaPrima = res;
  }

  private kilogramosDeEstrusion() {
    return this.data.materiasPrimas
      .filter((m) => m && m.extrusion)
      .reduce((prev, cur) => (prev += cur.kg!), 0);
  }

  private metrosPorSegundo() {
    const mat = this.data.materiasPrimas.filter(
      (m) => m && m.tipo === "LAMINA"
    );
    if (mat.length === 0) return 0;
    const mts = mat.map((m) => {
      let p1 = round((m.kg || 0) * 1000, roundValue);
      let p2 = round(
        (m.gramaje * this.isManga(this.data.anchoPt) * this.data.numBandas) /
          1000,
        roundValue
      );
      return round(p1 / p2, roundValue);
    });
    this.mtsPorSegundo = round(min(mts) / 60, roundValue);
  }

  private horasHombresParaProducto() {
    let res = new Set<string>();
    if (this.data.servicios?.length) {
      res = new Set<string>(this.data.servicios);
    } else {
      if (
        this.data.materiasPrimas.some(
          (m: IMateriaPrimaEmbedded) => m && m.extrusion && m.tipo === "LAMINA"
        )
      ) {
        res.add("EXTRUSION");
      }
      if (
        this.data.materiasPrimas.some(
          (m: IMateriaPrimaEmbedded) =>
            m && m.tipo === "TINTA" && m.nombre !== "SIN TINTA"
        )
      ) {
        res.add("IMPRESION");

        if (this.data.dobleImpresion) {
          res.add("IMPRESION 2");
        }

        res.add("REVISADO");
      }

      if (this.data.materiasPrimas.some((m) => m && m.tipo === "PARAFINA")) {
        res.add("PARAFINADO");
      }

      if (this.data.tipoDeVariable === "GOFRADO") {
        res.add("GOFRADO");
      }

      if (this.data.tipoDeProducto !== "MONOCAPA") {
        res.add("LAMINADO");
      }

      if (
        this.data.tipoDeProducto === "TRILAMINADO" ||
        this.data.tipoDeProducto === "TETRALAMINADO"
      ) {
        res.add("TRILAMINADO");
      }

      if (this.data.tipoDeProducto === "TETRALAMINADO") {
        res.add("TETRALAMINADO");
      }

      if (this.data.tipoDeVariable !== "SIN CORTE") {
        res.add("CORTE");
      }

      if (this.data.tipoDeVariable === "REFILADO") {
        res.add("REFILADO");
      }

      if (
        this.data.selladora.nombre !== "NO ES BOLSA" &&
        this.data.selladora.nombre !== "EXTERNO"
      ) {
        res.add("SELLADO");
      }

      if (this.data.selladora.nombre === "EXTERNO") {
        res.add("SELLADO EXT.");
      }
    }
    return Array.from(res) as string[];
  }

  private calcularHoraHombre() {
    const horasHombre = this.horasHombresParaProducto();
    if (!this.talleres) {
      this.data.horasHombre = [];
      return;
    }
    this.data.horasHombre = horasHombre.map((nombre: string) => {
      let val: any = this.data.horasHombre.find((h) => h.name === nombre);
      let taller = (this.talleres ? this.talleres : db.horasHombre).find(
        (h) => h.nombre == nombre
      );
      if (!val) {
        let defaults = this.talleres;
        val = defaults.find((h) => h.nombre == nombre);
      }

      if (nombre === "IMPRESION 2") {
        val = this.data.horasHombre.find((h) => h.name === "IMPRESION");
        if (!val) {
          let defaults = this.talleres;
          val = defaults.find((h) => h.nombre === "IMPRESION");
        }
      }

      let efectivo = 0;
      if (nombre === "EXTRUSION") {
        efectivo = round(
          this.kilogramosDeEstrusion() / val.velocidad,
          roundValue
        );
      } else if (nombre === "SELLADO") {
        val.tarifa = val.override ? val.tarifa : this.data.selladora.tarifa;
        val.velocidad = val.override ? val.velocidad : this.data.velocidad;
        efectivo = round(this.numBolsas / val.velocidad / 60, roundValue);
      } else if (nombre === "SELLADO EXT.") {
        if (!this.data.selladoExterno)
          this.data.selladoExterno = { sellado: 0, transporte: 0 };
        val.tarifa = val.override
          ? val.tarifa
          : round(
              (this.data.selladoExterno.sellado * this.numBolsas) / 1000 +
                this.data.selladoExterno.transporte * this.data.lote,
              roundValue
            );
        efectivo = 0;
      } else {
        efectivo = round(this.mtsPorSegundo / val.velocidad, roundValue);
      }

      let preparacion = val.override
        ? val.preparacion
        : val.nombre === "IMPRESION"
        ? 2
        : 1;

      if (nombre.includes("IMPRESION") && this.data.impresora?.nombre) {
        const impresora = this.data.impresora as IImpresora;
        val.tarifa = val.override ? val.tarifa : impresora.tarifa;
        preparacion = val.override
          ? val.preparacion
          : impresora.tazaPreparacion;
        val.velocidad = val.override ? val.velocidad : impresora.velocidad;
      }

      const r = {
        tarifa: val.tarifa,
        preparacion,
        efectivo,
        total: 0,
        costo: 0,
        velocidad: val.velocidad,
        name: val.nombre || val.name,
        extrusionName: val.extrusionName,
        costoAdministrativo: 0,
        override: val.override,
      };

      r.total = r.preparacion + r.efectivo;
      r.costo = round(r.tarifa * r.total, roundValue);
      r.costoAdministrativo = round(
        r.total * (taller.tarifaAdministracion || 0),
        roundValue
      );
      return r;
    });
  }

  private calcularTotales() {
    const sensibilidad: ISensibilidad = this.data.sensibilidad.find(
      (s) => s.lote === this.data.lote
    ) as ISensibilidad;
    this.costoTotal = round(
      this.data.horasHombre.reduce((p, c) => (p += c.costo), 0) +
        this.totalesMateriaPrima.costo,
      roundValue
    );
    this.costoBrutoUnitario = round(
      this.costoTotal / this.data.lote,
      roundValue
    );
    this.utilidad = round(
      this.data.precioUnitario - this.costoBrutoUnitario,
      roundValue
    );
    this.margenBruto = round(
      this.utilidad / this.data.precioUnitario,
      roundValue
    );
  }

  private totalCostForTypes(types: string[]) {
    return this.data.materiasPrimas
      .filter((m) => m && types.includes(m.tipo))
      .reduce((p, c) => (p += c.costoTotal), 0);
  }

  private calcularConvParaTable() {
    if (this.data.selladora.nombre === "EXTERNO") {
      const { merma } = this.data.selladoExterno;
      const hh = round(
        (this.data.horasHombre
          .filter((h) => h.name !== "EXTRUSION")
          .reduce((p, c) => (p += c.costo), 0) /
          this.data.lote) *
          (1 + merma),
        roundValue
      );
      const add = round(
        (this.loteTable.mp +
          this.loteTable.tinta +
          this.loteTable.adh +
          this.loteTable.ext) *
          merma,
        roundValue
      );
      return round(hh + add, roundValue);
    } else {
      const hh = this.data.horasHombre
        .filter((h) => h.name !== "EXTRUSION")
        .reduce((p, c) => (p += c.costo), 0);
      return round(hh / this.data.lote, roundValue);
    }
  }

  private calcularKiloMillar(gm2: number) {
    if (this.data.selladora.nombre === "NO ES BOLSA")
      return (this.loteTable.precioMillar = 0);
    this.loteTable.precioMillar =
      ((gm2 * this.data.frecuencia * this.isManga(this.data.anchoPt)) /
        1000000) *
      this.loteTable.precio;
  }

  private calcularZipper(gm2: number) {
    if (this.data.selladora.nombre === "NO ES BOLSA") return;
    let factor = this.data.zipper?.precio || 0;

    // const partOne = (1000 / this.data.frecuencia) * 1.05;
    // const partTwo = gm2 * this.data.frecuencia * this.isManga(this.data.anchoPt);
    let f =
      (((this.numBolsas * this.data.frecuencia) / 1000 / 1000) * factor) /
      this.data.lote;
    // this.loteTable.zipper = round(factor / partOne / partTwo * 1000000, roundValue);
    this.loteTable.zipper = round(f, roundValue);
  }

  private calcularValvula() {
    if (this.data.selladora.nombre === "NO ES BOLSA") return;
    this.loteTable.valvula = this.data.valvula?.precio || 0;
  }

  private calcularFilaTabla() {
    const margen = this.margenBase ? this.margenBase : this.margenBruto;
    const mp = round(
      this.totalCostForTypes(["LAMINA", "PARAFINA"]) / this.data.lote,
      roundValue
    );
    const tinta = round(
      this.totalCostForTypes(["TINTA", "SOLVENTE", "SOLVENTE"]) /
        this.data.lote,
      roundValue
    );
    const adh = round(
      this.totalCostForTypes(["ADHESIVO"]) / this.data.lote,
      roundValue
    );
    this.loteTable.mp = mp;
    this.loteTable.tinta = tinta;
    this.loteTable.adh = adh;

    const ext =
      this.data.horasHombre.find((h) => h.name === "EXTRUSION")?.costo || 0;
    this.loteTable.ext = round(ext! / this.data.lote, roundValue);
    const conv = this.calcularConvParaTable();
    this.loteTable.conv = conv;

    this.loteTable.gastoAdministrativo =
      this.getGastosAdministrativos() / this.data.lote;

    const mpSum =
      this.loteTable.mp +
      this.loteTable.tinta +
      this.loteTable.adh +
      this.loteTable.ext +
      this.loteTable.conv;
    this.loteTable.precio = round(mpSum / (1 - margen), roundValue);

    this.loteTable.exp = this.data.exporte
      ? round(0.008 * this.loteTable.precio, roundValue)
      : 0;
    this.loteTable.financiamiento = round(
      ((((this.loteTable.precio * this.data.lote) / 12 / 30) *
        this.data.diasDeCredito) /
        this.data.lote) *
        0.1,
      roundValue
    );
    let gm2 = this.data.materiasPrimas.reduce(
      (prev, cur) => (prev += cur ? cur.gramaje : 0),
      0
    );
    this.calcularKiloMillar(gm2);
    this.calcularZipper(gm2);
    this.calcularValvula();

    if (this.loteTable.tucos === null) this.loteTable.tucos = 0;
    if (this.loteTable.cintaDeEmbalaje == null)
      this.loteTable.cintaDeEmbalaje = 0;

    this.loteTable.comV = round(
      (mpSum +
        this.loteTable.cajas +
        this.loteTable.pines +
        (this.loteTable.zipper || 0) +
        (this.loteTable.valvula || 0)) *
        (this.data.comision / 100),
      3
    );
    this.loteTable.costoUnidad = round(
      mpSum +
        this.loteTable.clisses +
        this.loteTable.financiamiento +
        this.loteTable.flete +
        this.loteTable.exp +
        this.loteTable.cajas +
        this.loteTable.pines +
        (this.loteTable.zipper || 0) +
        (this.loteTable.valvula || 0) +
        this.loteTable.otros +
        this.loteTable.comV +
        (this.loteTable.tucos || 0) +
        (this.loteTable.cintaDeEmbalaje || 0) +
        this.loteTable.gastoAdministrativo,
      roundValue
    );

    this.loteTable.margen = round(
      (this.loteTable.precio - this.loteTable.costoUnidad) /
        this.loteTable.precio,
      roundValue
    );
  }

  public getTotalesSensibilizados() {
    const sensibilidad: ISensibilidad = this.data.sensibilidad.find(
      (s) => s.lote === this.data.lote
    ) as ISensibilidad;
    const margenNeto = sensibilidad.precio - sensibilidad.costoUnidad;
    const margen = margenNeto / sensibilidad.precio;
    const precioMillar = sensibilidad.precioMillar;
    const costoUnidad = sensibilidad.costoUnidad;
    const gastosAdministrativos =
      this.getGastosAdministrativos() / this.data.lote;
    const aporte =
      sensibilidad.conv + sensibilidad.ext + margenNeto + gastosAdministrativos;
    const otros = round(
      sensibilidad.clisses +
        sensibilidad.financiamiento +
        sensibilidad.flete +
        sensibilidad.exp +
        sensibilidad.cajas +
        sensibilidad.pines +
        (sensibilidad.valvula || 0) +
        sensibilidad.otros +
        sensibilidad.comV +
        (sensibilidad.tucos || 0) +
        (sensibilidad.cintaDeEmbalaje || 0),
      roundValue
    );
    return {
      margenNeto,
      aporte,
      margen,
      precioMillar,
      costoUnidad,
      otros,
      gastosAdministrativos,
      zipper: sensibilidad.zipper,
    };
  }

  public getMetrosLinealesPorKg() {
    let gramosPorMetro =
      ((this.anchoIMP - this.data.refileGeneral) *
        100 *
        this.totalesMateriaPrima.gramaje) /
      100000;
    let gramosPorKilo = 1000 / gramosPorMetro;
    return round(this.data.lote * gramosPorKilo, roundValue);
  }

  public getMetrosCuadrados() {
    let eq = (this.data.lote * 1000) / this.totalesMateriaPrima.gramaje;
    return round(eq, 0);
  }

  public getMillaresDeImpresiones() {
    let gramosPorImpresion =
      (this.isManga(this.data.anchoPt) *
        this.data.frecuencia *
        this.totalesMateriaPrima.gramaje) /
      1000000;
    let impresionesPorKg = 1000 / gramosPorImpresion;
    return round((this.data.lote * impresionesPorKg) / 1000, roundValue);
  }

  public getMillaresDeImpresionesParaLote(lote: number) {
    let gramosPorImpresion =
      (this.isManga(this.data.anchoPt) *
        this.data.frecuencia *
        this.totalesMateriaPrima.gramaje) /
      1000000;
    let impresionesPorKg = 1000 / gramosPorImpresion;
    return round((lote * impresionesPorKg) / 1000, roundValue);
  }

  public getMillaresBolsa() {
    return round(this.data.lote / this.kiloMillar, roundValue);
  }

  public getGastosAdministrativos() {
    return round(
      this.data.horasHombre.reduce(
        (_: number, c: IHorasHombre) => (_ += c.costoAdministrativo || 0),
        0
      ),
      roundValue
    );
  }
}

export default Cotizacion;
