import * as React from 'react'
import DefaultLayout from '../../layouts/DefaultLayout';
import { CircularProgress, makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    center: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        height: '100vh',
    }
}));

interface Props {
}

const LoadingScreen: React.FC<Props> = (props) => {
    const classes = useStyles();
    return (
        <div className={classes.center}>
            <div>
                <CircularProgress size="4rem" />
            </div>
            <br/>
            <div>
                <Typography variant="h5">Entrando a Megapack</Typography>
            </div>
        </div>
    )
}


export default LoadingScreen;