import { gql } from "apollo-boost";

export const SECURITY_MUTATIONS = {
  LOGIN: gql`
    mutation login($email: String!, $password: String!) {
      login(email: $email, password: $password) {
        accessToken
        refreshToken
        user {
          _id
          first
          last
          email
          role
        }
      }
    }
  `,
  SEND_RESET_PASSWORD: gql`
    mutation sendResetPassword($email: String!) {
      sendResetPassword(email: $email)
    }
  `,
  CHANGE_PASSWORD: gql`
    mutation changePassword($newPw: String!) {
      changePassword(newPw: $newPw)
    }
  `,
  RESET_PASSWORD: gql`
    mutation resetPassword($password: String!) {
      resetPassword(password: $password)
    }
  `,
};
