import { useMutation } from "@apollo/react-hooks";
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Container,
  CssBaseline,
  Link,
  TextField,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import qs from "qs";
import * as React from "react";
import { Redirect, useHistory, useLocation } from "react-router-dom";

// Utils
import Toast from "../components/singletons/Toast";
import { SECURITY_MUTATIONS } from "../graphql/mutations/security.mutations";
import GraphQLErrorHandler from "../utils/GraphQLErrorHandler";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://material-ui.com/">
        Megapack
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  buttonProgress: {
    color: grey[800],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

const isValidPassword = (password: string): boolean => {
  const passwordRegex = /^(?=.*\d)(?=.*[A-Z])(?=.*[#@$&!]).{8,}$/;
  return passwordRegex.test(password);
};

const ChangePassword: React.FC<any> = () => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const { token } = qs.parse(location.search, { ignoreQueryPrefix: true });
  // States
  const [password, setPassword] = React.useState("");
  const [confirm, setConfirm] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState("");
  const [resetPassword] = useMutation(SECURITY_MUTATIONS.RESET_PASSWORD);

  const matching = password.length && password === confirm;

  const reset = async (e: any) => {
    e.preventDefault();
    try {
      const valid = isValidPassword(password);
      if (!valid) {
        setError(
          "La no tiene al menos 8 caracteres, una mayúscula, un número y un caracter especial."
        );
        return;
      }
      setLoading(true);
      setLoading(false);
      // @ts-ignore
      await resetPassword({
        variables: { password },
        context: { headers: { "reset-token": token } },
      });
      Toast.show(
        { severity: "success", text: "Contraseña actualizada." },
        2000
      );
      setTimeout(function () {
        history.replace("/");
      }, 2000);
    } catch (err) {
      let msg = new GraphQLErrorHandler(err).getErrorMessage();
      setLoading(false);
      Toast.show({ severity: "error", text: msg }, 3000);
    }
  };

  if (!token) return <Redirect to="/" />;

  return (
    <React.Fragment>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Cambiar Contraseña
          </Typography>
          <Typography variant="body2" color="textSecondary" align="center">
            La contraseña debe tener al menos 8 caracteres, una mayúscula, un
            número y un caracter especial.
          </Typography>
          <form className={classes.form} noValidate>
            <TextField
              margin="normal"
              required
              fullWidth
              autoFocus
              label="Nueva Contraseña"
              type="password"
              id="password"
              value={password}
              onChange={(e: any) => setPassword(e.target.value)}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Confirmar Contraseña"
              type="password"
              id="password"
              value={confirm}
              onChange={(e: any) => setConfirm(e.target.value)}
              error={!!password.length && !matching}
              helperText={
                !!password.length && !matching && "Contraseñas no coinciden."
              }
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={reset}
              disabled={!matching || loading}
            >
              Cambiar Contraseña
              {loading && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </Button>
          </form>
        </div>
        {error && (
          <Box mt={5}>
            <Typography variant="body2" color="textSecondary" align="center">
              {error}
            </Typography>
          </Box>
        )}
        <Box mt={8}>
          <Copyright />
        </Box>
      </Container>
    </React.Fragment>
  );
};

export default ChangePassword;
