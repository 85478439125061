import { gql } from "apollo-boost";

export const materiasPrimas = `
materiasPrimas {
    _id
    densidad
    micras
    gramaje
    costo
    refile
    nombre
    tipo
    extrusion
    kg
    index
    comodin
    scrap
    participacion
    anchoImprenta
    costoTotal
}`;

export const sensibilidad = `
sensibilidad {
    lote
    precio
    costoUnidad
    mp
    tinta
    adh
    conv
    ext
    clisses
    financiamiento
    flete
    exp
    cajas
    pines
    zipper 
    otros
    margen
    scrap
    precioMillar
    comV
    valvula
    tucos
    cintaDeEmbalaje,
    gastoAdministrativo
}`;

export const cotizacion = `
    _id
    cliente {
        _id
        nombreCliente
        codigoInterno
    }
    producto
    comision
    diasDeCredito
    mac
    lote
    tipoDeProducto
    tipoDeVariable
    refileGeneral
    anchoPt
    numBandas
    numColores
    frecuencia
    repeticiones
    fondeado
    clisses
    tipoDeClisses
    clissesCm2
    selladora {
        _id
        nombre
        tarifa
        tipoDeBolsas
    }
    impresora {
        _id
        nombre
        velocidad
        tarifa
        tazaPreparacion
        limiteImpresion
        numColores
        cilindros
    }
    zipper {
        _id
        nombre
        precio
    }
    valvula {
        _id
        nombre
        precio
    }
    velocidad
    tipoDeBolsa
    b22
    exporte
    costoTotal
    costoBrutoUnitario
    precioUnitario
    utilidad
    margenBruto
    ${materiasPrimas}
    horasHombre {
        name
        tarifa
        preparacion
        efectivo
        total
        costo
        velocidad
        override
        extrusionName
        costoAdministrativo
    }
    empleado {
        _id
        first
        last
    }
    aprobadaPor {
        _id
        first
        last
    }
    selladoExterno {
        merma
        sellado
        transporte
    }
    aprobado
    aprobadaPor {
        first
    }
    createdAt
    updatedAt
    ${sensibilidad}
    servicios
    codigo
    version
    solicitud
    observaciones
    codigoPago
    estandar
    dobleImpresion
    muestra
`;

export const COTIZACION_QUERIES = {
  GET_ALL: gql`
    query getCotizaciones($page: Int!, $limit: Int!, $search: String!) {
      getCotizaciones(page: $page, limit: $limit, search: $search) {
        total
        page
        limit
        cotizaciones {
          _id
          producto
          cliente {
            _id
            nombreCliente
            codigoInterno
          }
          createdAt
          updatedAt
          empleado {
            first
            last
          }
          aprobado
          codigo
          eliminada
          solicitud
        }
      }
    }
  `,
  GET: gql`
        query getCotizacion($codigo: String!){
            getCotizacion(codigo: $codigo){
                ${cotizacion}
                deltaAprobacion
                estandar
            }
        }
    `,
  SEARCH: gql`
        query searchCotizacion($cliente: String!) {
            searchCotizacion(cliente: $cliente) {
                ${cotizacion}
            }
        }
    `,
  GET_CHANGES: gql`
    query getCotizacionChanges($cotizacion: String!) {
      getCotizacionChanges(cotizacion: $cotizacion) {
        field
        oldValue
        newValue
      }
    }
  `,
};
