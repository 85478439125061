import * as React from 'react'
import { Container, makeStyles, Typography, FormGroup, TextField, TextareaAutosize, Button, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import { DropzoneArea } from 'material-ui-dropzone';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: 10,
        paddingBottom: 30,
        '& .MuiTextField-root': {
            marginBottom: theme.spacing(2)
        },
        '& input.Mui-disabled': {
            opacity: 1,
            color: '#FFF',
            backgroundColor: theme.palette.primary.main,
            paddingLeft: 4
        },
        '& .MuiInputBase-input.Mui-disabled': {
            opacity: 1,
            color: '#FFF',
            backgroundColor: theme.palette.primary.main,
            paddingLeft: 4
        },
        '& .MuiInputLabel-shrink': {
            color: grey[900],
        }
    },
    language: {
        marginBottom: 10
    }
}));


interface Props {
    form: any,
    updateForm: any,
    contacto: any,
    updateContacto: any,
    upload: any,
    disabled: boolean,
    setFiles: any,
    lang: string
}


const ReclamoIngles: React.FC<Props> = (props) => {
    const { form, updateForm, contacto, updateContacto, upload, disabled, setFiles, lang } = props;
    const classes = useStyles();
    const history = useHistory();

    const handleChange = (e) => {
        history.push({
            pathname: '/reclamos',
            search: `?lang=${e.target.value}`
        })
    }

    const error = parseFloat(form.cantidadRechazada) > parseFloat(form.cantidad);
    return (
        <Container className={classes.root} maxWidth="md">
            <div><img src="/imgs/megapack.jpg" /></div>
            <FormControl className={classes.language}>
                <InputLabel >Language</InputLabel>
                <Select
                    value={lang}
                    onChange={handleChange}
                >
                    <MenuItem value={'es'}>Spanish</MenuItem>
                    <MenuItem value={'en'}>English</MenuItem>
                </Select>
            </FormControl>
            <Typography variant="h4">Complaint Form</Typography>
            <Typography variant="h6">Please fill out the form and our support team will be in contact shortly.</Typography>

            <form >
                <FormGroup>
                    <TextField required label="Company Name" value={form.cliente} onChange={updateForm} name="cliente" />
                    <TextField required label="Product Name" value={form.producto} onChange={updateForm} name="producto" />
                    <TextField required label="G.R. Number (Megapack)" value={form.gr} onChange={updateForm} name="gr" />
                    <TextField required label="Account Executive" value={form.ejecutivo} onChange={updateForm} name="ejecutivo" />
                    <TextField required label="Lot Number" value={form.numLote} onChange={updateForm} name="numLote" />
                    <TextField
                        required
                        label="Total Quantity Shipped (Kg o Millar):"
                        value={form.cantidad}
                        onChange={updateForm}
                        name="cantidad"
                        type="number"
                        inputProps={{
                            type: 'number'
                        }}
                    />
                    <TextField
                        required
                        label="Quantity Rejected (Kg o Millar):"
                        value={form.cantidadRechazada}
                        onChange={updateForm}
                        name="cantidadRechazada"
                        inputProps={{
                            type: 'number'
                        }}
                        error={error}
                        helperText={error && "Cantidad rechazada mayor a cantidad despachada"}
                    />
                    <Typography>Reason for rejection</Typography>
                    <TextareaAutosize
                        required
                        value={form.motivo}
                        onChange={updateForm}
                        name="motivo"
                        rowsMin={3}
                    />
                    <Typography variant="h6">Contact</Typography>
                    <TextField required label="Name" value={contacto.nombre} onChange={updateContacto} name="nombre" />
                    <TextField required label="Position" value={contacto.cargo} onChange={updateContacto} name="cargo" />
                    <TextField required label="Email" value={contacto.email} onChange={updateContacto} name="email" />
                    <TextField required label="Phone Number" value={contacto.telefono} onChange={updateContacto} name="telefono" />
                    <Typography variant="h6">Documentation - Photos or Videos</Typography>
                    <DropzoneArea dropzoneText="Add or drop files here" onChange={setFiles} maxFileSize={500000000} />
                    <br /><br />
                    <Button variant="contained" color="primary" onClick={upload} disabled={disabled}>Create Complaint</Button>
                </FormGroup>
            </form>
        </Container>)
}


export default ReclamoIngles;