import * as React from 'react'
import { Typography, makeStyles, Container, TextField, FormGroup, FormControlLabel, Checkbox, Button, CircularProgress, Grid, TextareaAutosize } from '@material-ui/core'
import { grey } from '@material-ui/core/colors';
import { DropzoneArea } from 'material-ui-dropzone';
import { clUploader } from '../vendors/Cloudinary';
import { useMutation } from '@apollo/react-hooks';
import { RECLAMO_MUTATIONS } from '../graphql/mutations/reclamo.mutations';
import { useLocation } from 'react-router-dom';
import ReclamoEspanol from '../components/reclamos/ReclamoEspanol';
import ReclamoIngles from '../components/reclamos/ReclamoIngles';
import qs from 'qs';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: 10,
        paddingBottom: 30,
        '& .MuiTextField-root': {
            marginBottom: theme.spacing(2)
        },
        '& input.Mui-disabled': {
            opacity: 1,
            color: '#FFF',
            backgroundColor: theme.palette.primary.main,
            paddingLeft: 4
        },
        '& .MuiInputBase-input.Mui-disabled': {
            opacity: 1,
            color: '#FFF',
            backgroundColor: theme.palette.primary.main,
            paddingLeft: 4
        },
        '& .MuiInputLabel-shrink': {
            color: grey[900],
        }
    }
}));

const helpTexts = {
    'es': {
        success: 'Hemos recibido tu reclamo, nuestro equipo te contactará pronto.',
        upload: 'Subiendo Archivos'
    },
    'en': {
        success: 'We have received your complaint, our team will be in contact shortly',
        upload: 'Uploading Files'
    }
}

const ReclamosScreen: React.FC = (props) => {
    const classes = useStyles();
    const location = useLocation();
    const [form, setForm] = React.useState<any>({});
    const [files, setFiles] = React.useState<File[]>([]);
    const [documents, setDocuments] = React.useState<any[]>([]);
    const [contacto, setContacto] = React.useState<any>({});
    const [uploadIndex, setUploadIndex] = React.useState(-1);
    const [fileProgress, setFileProgress] = React.useState(0);
    const [uploading, setUploading] = React.useState(false);
    const [completed, setCompleted] = React.useState(false);

    let lang = 'es';
    if (location.search) {
        let query:any = qs.parse(location.search.replace('?', ''), {});
        lang = query.lang || 'es';
    }

    const [language, setLanguage] = React.useState<string>(lang);
    const [createReclamo] = useMutation(RECLAMO_MUTATIONS.CREATE);

    const updateForm = (e) => {
        setForm(Object.assign({}, form, { [e.target.name]: e.target.value }));
    }

    const updateContacto = (e) => {
        setContacto(Object.assign({}, contacto, { [e.target.name]: e.target.value }));
    }

    React.useEffect(() => {
        if (location.search) {
            let query: any = qs.parse(location.search.replace('?', ''), {});
            lang = query.lang || 'es';
            setLanguage(lang);
        }
    }, [location.search])

    const upload = () => {
        if (files.length) {
            setUploading(true);
            setUploadIndex(0);
        } else {
            setCompleted(true);
            create();
        }
    }

    const updateProgress = (progress: number) => setFileProgress(progress)

    const done = (response: any) => {
        const { data } = response;
        const docs = [...documents, {
            publicId: data.public_id,
            format: data.format,
            type: data.resourse_type,
            url: data.url
        }];
        setDocuments(docs);
        if (uploadIndex < files.length) {
            setUploadIndex(uploadIndex + 1)
        }
    }

    const create = () => {
        const dto = {
            ...form,
            cantidadRechazada: parseFloat(form.cantidadRechazada) || 0,
            cantidad: parseFloat(form.cantidad) || 0,
            contacto,
            documentos: documents
        };
        createReclamo({ variables: { dto } });
    }

    React.useEffect(() => {
        if (uploading) {
            if (uploadIndex < files.length) {
                setFileProgress(0);
                clUploader.upload(files[uploadIndex], updateProgress, done);
            } else {
                setCompleted(true);
                create();
            }
        }
    }, [uploadIndex])

    const disabled = () => {
        return !form.cliente ||
            !form.producto ||
            !form.gr ||
            !form.ejecutivo ||
            !form.numLote ||
            !form.cantidad ||
            !form.cantidadRechazada ||
            !contacto.nombre ||
            !contacto.cargo ||
            !contacto.email ||
            !contacto.telefono
    }

    if (completed) {
        return <Container className={classes.root} maxWidth="md">
            <img src="/imgs/megapack.jpg" />
            <Typography variant="h4">{helpTexts[lang].success}</Typography>
        </Container>
    }

    if (uploading) {
        return <Container className={classes.root} maxWidth="md">
            <img src="/imgs/megapack.jpg" />
            <Typography variant="h4" align="center">{helpTexts[lang].success}</Typography>
            <br />
            <Grid container alignItems="center" justify="center" direction="column">
                <Grid item justify="center">
                    <CircularProgress size={60} />
                </Grid>
                <Grid item justify="center">
                    {fileProgress} %
                </Grid>
            </Grid>
        </Container>
    }

    switch (language) {
        case 'en':
            return <ReclamoIngles
                form={form}
                updateForm={updateForm}
                contacto={contacto}
                updateContacto={updateContacto}
                upload={upload}
                disabled={disabled()}
                setFiles={setFiles}
                lang={lang}
            />
        default:
        case 'es':
            return <ReclamoEspanol
                form={form}
                updateForm={updateForm}
                contacto={contacto}
                updateContacto={updateContacto}
                upload={upload}
                disabled={disabled()}
                setFiles={setFiles}
                lang={lang}
            />
    }
}


export default ReclamosScreen;