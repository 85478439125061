import { GraphQLError } from "graphql";

interface GraphQLErrorResponse {
    graphQLErrors: GraphQLError[],
    message: string,
    networkError?: number | string | null
}

class GraphQLErrorHandler {

    response: GraphQLErrorResponse;
    error: any;

    constructor(error: any) {
        let parsed = JSON.parse(JSON.stringify(error));
        this.response = parsed;
        this.error = error;
    }

    getErrorMessage() {
        if (this.response && this.response.graphQLErrors) {
            if (this.response.graphQLErrors.length === 0) {
                return this.error.message;
            };
            return this.response.graphQLErrors[0].message;
        } else {
            return this.error.message;
        }
    }
}

export default GraphQLErrorHandler;