export default {
    "productos": [
        {
            "name": "MONOCAPA",
            "recipe": [
                "LAMINA",
                "TINTA",
                "SOLVENTE"
            ]
        },
        {
            "name": "BILAMINADO",
            "recipe": [
                "LAMINA",
                "LAMINA",
                "TINTA",
                "ADHESIVO",
                "SOLVENTE",
                "SOLVENTE ADHESIVO"
            ]
        },
        {
            "name": "TRILAMINADO",
            "recipe": [
                "LAMINA",
                "LAMINA",
                "LAMINA",
                "TINTA",
                "ADHESIVO",
                "ADHESIVO",
                "SOLVENTE",
                "SOLVENTE ADHESIVO"
            ]
        },
        {
            "name": "TETRALAMINADO",
            "recipe": [
                "LAMINA",
                "LAMINA",
                "LAMINA",
                "LAMINA",
                "TINTA",
                "ADHESIVO",
                "ADHESIVO",
                "ADHESIVO",
                "SOLVENTE",
                "SOLVENTE ADHESIVO"
            ]
        }
    ],
    "variables": [
        {
            "name": "NINGUNO",
            "recipe": []
        },
        {
            "name": "PARAFINADO",
            "recipe": [
                "PARAFINA"
            ]
        },
        {
            "name": "REFILADO",
            "recipe": []
        },
        {
            "name": "GOFRADO",
            "recipe": []
        },
        {
            "name": "SIN CORTE",
            "recipe": []
        }
    ],
    "sellados": [
        "NO ES BOLSA",
        "HECE",
        "HECE 3",
        "HUDSON 1",
        "HUDSON 2",
        "EXTERNO"
    ],
    "tiposDeBolsas": [
        "Ninguna",
        "Sellado T",
        "Sellado U",
        "2 Laterales",
        "Doypack",
        "SUP",
        "Sello lateral con solapa sin fuelle",
        "Sello lateral con solapa con fuelle",
        "Sello lateral con solapa fondo redondo",
        "Sello lateral con solapa fondo chevrom",
        "Sello T sin fuelle",
        "Sello T con fuelle",
        "Sello stabilo",
        "Sello fondo con fuelle",
        "Sello fondo sin fuelle",
        "Pouch sello lateral",
        "Pouch sello U",
        "SUP sello K sin base",
        "SUP sello K con base",
        "SUP sello redondo (doypack)",
        "Todas las anteriores"
    ],
    "zipper": [
        "NO",
        "03",
        "10"
    ],
    "horasHombre": [
        {
            "nombre": "EXTRUSION",
            "velocidad": 200,
            "tarifaAdministracion": 1,
            "tarifa": 30,
            "kgs": 0
        },
        {
            "nombre": "IMPRESION",
            "velocidad": 180,
            "tarifaAdministracion": 1,
            "tarifa": 135,
            "kgs": 0
        },
        {
            "nombre": "IMPRESION 2",
            "velocidad": 180,
            "tarifaAdministracion": 1,
            "tarifa": 135,
            "kgs": 0
        },
        {
            "nombre": "REVISADO",
            "velocidad": 200,
            "tarifaAdministracion": 1,
            "tarifa": 30,
            "kgs": 0
        },
        {
            "nombre": "LAMINADO",
            "velocidad": 180,
            "tarifaAdministracion": 1,
            "tarifa": 55,
            "kgs": 0
        },
        {
            "nombre": "PARAFINADO",
            "velocidad": 30,

            "tarifaAdministracion": 1, "tarifa": 25,
            "kgs": 0
        },
        {
            "nombre": "TRILAMINADO",
            "velocidad": 130,
            "tarifaAdministracion": 1,
            "tarifa": 55,
            "kgs": 0
        },
        {
            "nombre": "TETRALAMINADO",
            "velocidad": 130,
            "tarifaAdministracion": 1,
            "tarifa": 55,
            "kgs": 0
        },
        {
            "nombre": "REFILADO",
            "velocidad": 65,

            "tarifaAdministracion": 1, "tarifa": 30,
            "kgs": 0
        },
        {
            "nombre": "CORTE",
            "velocidad": 90,
            "gastoAdministrativo": 1, "tarifa": 30,
            "kgs": 0
        },
        {
            "nombre": "SELLADO",
            "velocidad": 50,

            "tarifaAdministracion": 1, "tarifa": 16,
            "kgs": 0
        },
        {
            "nombre": "FONDEADO",
            "velocidad": 150,
            "tarifaAdministracion": 1,
            "tarifa": 55,
            "kgs": 0
        },
        {
            "nombre": "GOFRADO",
            "velocidad": 35,

            "tarifaAdministracion": 1, "tarifa": 25,
            "kgs": 0
        },
        {
            "nombre": "SELLADO EXT.",
            "velocidad": 1,

            "tarifaAdministracion": 1, "tarifa": 2.5,
            "kgs": 0,
            "mts": 1
        }
    ]
}