import * as React from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/react-hooks';
import { grey } from '@material-ui/core/colors';
import { Typography, CircularProgress, Link, makeStyles, Container, CssBaseline, Avatar, TextField, FormControlLabel, Checkbox, Button, Grid, Box } from '@material-ui/core';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';

// Utils
import { SECURITY_MUTATIONS } from '../graphql/mutations/security.mutations';
import { UserContext } from '../contexts/UserContext';
import ForgotPasswordDialog from '../components/dialogs/ForgotPassword';
import Toast from '../components/singletons/Toast';
import GraphQLErrorHandler from '../utils/GraphQLErrorHandler';
import { AUTH_TOKEN } from '../constants/auth';
import { UserRole } from '../constants/userRoles';

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://megapackgroup.com/">
                Megapack
      </Link>{' '}
            {new Date().getFullYear()}
            {'. V 0.4'}
        </Typography>
    );
}

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    buttonProgress: {
        color: grey[800],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
}));

const Login: React.FC<any> = () => {
    const classes = useStyles();
    const location = useLocation();
    const history = useHistory();

    // States
    const [email, setEmail] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [loading, setLoading] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [rememberMe, setRememberMe] = React.useState(true);

    const [onLogin] = useMutation(SECURITY_MUTATIONS.LOGIN);
    const { setUser } = React.useContext(UserContext);


    const login = async (e: any) => {
        e.preventDefault();
        try {
            setLoading(true);
            const response = await onLogin({ variables: { email, password } });
            const { accessToken, user } = response.data.login;
            if (rememberMe) window.localStorage.setItem(AUTH_TOKEN, accessToken);
            window.sessionStorage.setItem(AUTH_TOKEN, accessToken);
            setUser(user);
            setLoading(false);

            // @ts-ignore
            if (location.state && location.state.from) {
                // @ts-ignore
                history.replace(location.state.from)
            } else {
                if(user.role === UserRole.PRODUCTION) return history.replace('/estandares');
                return history.replace('/');
            }
        } catch (err) {
            let msg = new GraphQLErrorHandler(err).getErrorMessage();
            setLoading(false);
            Toast.show({ severity: "error", text: msg }, 3000);
        }
    }

    return (
        <React.Fragment>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <div className={classes.paper}>
                    <Avatar className={classes.avatar}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">Iniciar Sesión</Typography>
                    <form className={classes.form} noValidate>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Email Address"
                            name="email"
                            autoComplete="email"
                            autoFocus
                            value={email}
                            onChange={(e: any) => setEmail(e.target.value)}
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            value={password}
                            onChange={(e: any) => setPassword(e.target.value)}
                        />
                        <FormControlLabel
                            control={<Checkbox value="remember" color="primary" onChange={() => setRememberMe(!rememberMe)} checked={rememberMe} />}
                            label="Recuerdame"
                        />
                        <Button
                            type="submit" fullWidth variant="contained" color="primary" className={classes.submit} onClick={login}
                            disabled={loading}
                        >
                            Iniciar Sesión
                        {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                        </Button>
                        <Grid container>
                            <Grid item xs>
                                <Link href="#" variant="body2" onClick={() => setOpen(true)}>¿Olvidaste tu contraseña?</Link>
                            </Grid>
                            {/* <Grid item>
                            <Link href="#" variant="body2">
                                {"Don't have an account? Sign Up"}
                            </Link>
                        </Grid> */}
                        </Grid>
                    </form>
                </div>
                <Box mt={8}><Copyright /></Box>
            </Container>
            <ForgotPasswordDialog open={open} handleClose={() => setOpen(false)} />
        </React.Fragment>
    );
}

export default Login;