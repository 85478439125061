import cloudinary, { Cloudinary } from 'cloudinary-core';
import axios, { AxiosResponse } from 'axios';

const cloudName = 'megapackgroup';

const cl = new Cloudinary({ 'cloud_name': cloudName, 'api_key': '466699181619461' });

const clUploader = {
    async upload(file, progress, done) {
        const config = {
            onUploadProgress: function (progressEvent) {
                var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
                progress(percentCompleted);
            }
        }

        const url = `https://api.cloudinary.com/v1_1/${cloudName}/upload`;

        let data = new FormData();
        data.append('file', file);
        data.append('upload_preset', 'reclamo_images');

        axios.post(url, data, config).then(done).catch(err => console.log(err));
    },
    async uploadAsync(file) {
        const url = `https://api.cloudinary.com/v1_1/${cloudName}/upload`;
        let data = new FormData();
        data.append('file', file);
        data.append('upload_preset', 'reclamo_images');
        return await axios.post(url, data);
    }
}

export { cl, clUploader };