import * as React from 'react'
import { grey } from '@material-ui/core/colors';
import { makeStyles, Divider, Grid } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import { COTIZACION_QUERIES } from '../../graphql/queries/cotizacion.queries';
import { SCRAP_QUERIES } from '../../graphql/queries/scrap.queries';
import Cotizacion, { round } from '../../common/Cotizacion';
import { defaultData } from '../../contexts/CotizacionContext';
import { ICotizacion, ISensibilidad } from '../../types/Cotizacion.types';
import { range } from 'underscore';
import ReactToPdf from 'react-to-pdf';
import InfoRow from '../_general/InfoRow';
import { ccyFormat, toDecimal } from '../../utils/DisplayUtils';

const useStyles = makeStyles((theme) => ({
    root: {
        width: 1080,
        height: 800,
        padding: 20,
        margin: '0 auto',
        '& h1,h2,h3,h4,h5': {
            margin: 0,
            marginBottom: 10
        }
    },
    head: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    row: {
        display: 'flex',
        flexDirection: 'row'
    },
    rowItem: {
        flex: 1
    },
    rowItemContainer: {
        flex: 1,
        marginRight: 20
    },
    rowItemRight: {
        flex: 1,
        textAlign: 'right'
    }
}));

const loteMap = {
    250: '0 a 499',
    500: '500 a 999',
    1000: '1000 a 1,999',
    2000: '2000 a 2,999',
    3000: '3,000 a 4,999',
    5000: '5,000 a 6,999',
    7000: '7,000 a 9,999',
    10000: '10,000 a 19,999',
    20000: '20,000 a más',
}

const MatriculaPdf: React.FC = (props) => {
    const classes = useStyles();
    const { id } = useParams();
    const cotizacionQuery = useQuery(COTIZACION_QUERIES.GET, { variables: { codigo: id } });
    const scraps = useQuery(SCRAP_QUERIES.GET);
    const [cotizacion, setCotizacion] = React.useState<Cotizacion>(new Cotizacion(defaultData, []));

    React.useEffect(() => {
        let interval;
        if (!cotizacionQuery.loading && !cotizacionQuery.error && !scraps.loading && !scraps.error) {
            if (scraps.data && data) {
                if (!cotizacion.data._id) {
                    const cotizacion = new Cotizacion(cotizacionQuery.data.getCotizacion, scraps.data.getScraps);
                    setCotizacion(cotizacion);
                }
            }
        }
        return () => clearInterval(interval)
    }, [cotizacionQuery.loading, scraps.loading]);

    React.useEffect(() => {
        if (cotizacion.data._id) {
            document.getElementById('pdf-container')?.click();
        }
    }, [cotizacion])

    const options = {
        orientation: 'landscape',
    };

    if (!cotizacion.data) return null;
    const data = cotizacion.data as ICotizacion;
    if (!data) return null;
    const isBag = data.selladora.nombre !== 'NO ES BOLSA';
    const solvente = (data.materiasPrimas.find(m => m && m.tipo === 'SOLVENTE')?.costoTotal || 0) / data.lote;
    const sensibilidad = data.sensibilidad.find((s: ISensibilidad) => s.lote === data.lote);
    if (!sensibilidad) return null;
    const { mp, tinta, adh } = sensibilidad;
    const totalMp = mp + tinta + adh + solvente;
    const totalCostos = sensibilidad.conv +
        sensibilidad.ext +
        sensibilidad.financiamiento +
        sensibilidad.clisses +
        sensibilidad.flete +
        sensibilidad.exp +
        sensibilidad.cajas +
        sensibilidad.pines +
        sensibilidad.zipper +
        sensibilidad.otros +
        sensibilidad.comV;
    return (
        <ReactToPdf options={options} filename={`matricula-para-cotizacion-${data.codigo}.pdf`}>
            {({ toPdf, targetRef }) => (
                <div className={classes.root} onClick={toPdf} ref={targetRef} id="pdf-container">
                    <div className={classes.head}>
                        <div style={{ flex: 2 }}>
                            <h2>{data.producto}</h2>
                            <h3>{data.cliente?.nombreCliente}</h3>
                            <h5>Nro. Cotizacion: {data.codigo}</h5>
                        </div>
                        <div style={{ flex: 1 }}>
                            <h3>Lote: {toDecimal(data.lote, 0)}</h3>
                            <h3>Unidad: {isBag ? 'MBL' : 'KG'}</h3>
                            <h3>Kilo Millar: {toDecimal(cotizacion.kiloMillar, 2)}</h3>
                            <h3>Merma: {(sensibilidad.scrap * 100).toFixed(2)}%</h3>
                        </div>
                    </div>
                    <h2>{loteMap[data.lote]}</h2>
                    <Divider />
                    <br />
                    <div className={classes.row}>
                        <div className={classes.rowItemContainer}>
                            <h3>Costo Materia Prima</h3>
                            <Divider />
                            <InfoRow left="MP" right={ccyFormat(sensibilidad.mp)} />
                            <InfoRow left="Tinta" right={ccyFormat(sensibilidad.tinta)} />
                            <InfoRow left="Adhesivos" right={ccyFormat(sensibilidad.adh)} />
                            <InfoRow left="Solventes" right={ccyFormat(round(solvente, 2))} />
                            <div className={classes.row}>
                                <h2 className={classes.rowItem}>Total</h2>
                                <h2 className={classes.rowItemRight}>{ccyFormat(totalMp)}</h2>
                            </div>
                        </div>
                        <div className={classes.rowItemContainer}>
                            <h3>Otros Costos</h3>
                            <Divider />
                            <InfoRow left="Conversión" right={ccyFormat(sensibilidad.conv)} />
                            <InfoRow left="Extrusión" right={ccyFormat(sensibilidad.ext)} />
                            <InfoRow left="G. Finaciero" right={ccyFormat(sensibilidad.financiamiento)} />
                            <InfoRow left="Clisses" right={ccyFormat(sensibilidad.clisses)} />
                            <InfoRow left="Fletes" right={ccyFormat(sensibilidad.flete)} />
                            <InfoRow left="G.Exportacion" right={ccyFormat(sensibilidad.exp)} />
                            <InfoRow left="Cajas" right={ccyFormat(sensibilidad.cajas)} />
                            <InfoRow left="Piners" right={ccyFormat(sensibilidad.pines)} />
                            <InfoRow left="Zipper" right={ccyFormat(sensibilidad.zipper)} />
                            <InfoRow left="Otros" right={ccyFormat(sensibilidad.otros)} />
                            <InfoRow left="Com. De Venta" right={`${sensibilidad.comV}`} />
                            <div className={classes.row}>
                                <h2 className={classes.rowItem}>Total</h2>
                                <h2 className={classes.rowItemRight}>{ccyFormat(totalCostos)}</h2>
                            </div>
                        </div>
                        <div className={classes.rowItem}>
                            <h3>Totales</h3>
                            <Divider />
                            <InfoRow left="Aporte" right={`${toDecimal(cotizacion.aporte, 2)} $/kg`} />
                            <InfoRow left="Margen" right={`${toDecimal(sensibilidad.margen * 100, 2)}%`} />
                            {isBag && <InfoRow left="Precio Millar" right={ccyFormat(sensibilidad.precioMillar)} />}
                            <InfoRow left="Costo Total US $" right={ccyFormat(sensibilidad.costoUnidad)} />
                            <InfoRow left="Precio Colocado" right={ccyFormat(data.precioUnitario)} />
                        </div>
                    </div>
                </div>
            )}
        </ReactToPdf>
    )
}


export default MatriculaPdf;