import * as React from 'react'
import { Container, Typography, Button, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiTypography-root': {
            marginBottom: theme.spacing(2)
        },
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    }
}));


const NotFound: React.FC = (props) => {
    const classes = useStyles();
    return (
        <Container maxWidth="xs" className={classes.root}>
            <Typography variant="h4" align="center">MEGAPACK</Typography>
            <Typography variant="h1" align="center" color="primary">404</Typography>
            <Typography variant="h5" align="center">No se encontro lo que estabas buscando</Typography>
            <Typography align="center">
                <Button variant="contained" color="primary" href="/">Regresar a <span role="img" aria-label="home">🏡</span></Button>
            </Typography>
        </Container>

    )
}


export default NotFound;