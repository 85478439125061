import AccountBox from "@material-ui/icons/AccountBox";
import AssessmentIcon from "@material-ui/icons/Assessment";
import Assignment from "@material-ui/icons/Assignment";
import Description from "@material-ui/icons/Description";
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";
import LiveHelpIcon from "@material-ui/icons/LiveHelp";
import ReceiptIcon from "@material-ui/icons/Receipt";
import Storage from "@material-ui/icons/Storage";
import React from "react";
import MatriculaPdf from "./components/matricula/MatriculaPdf";
import { UserRole } from "./constants/userRoles";
const Cotizador = React.lazy(() => import("./screens/cotizador"));
const Users = React.lazy(() => import("./screens/users"));
const AddUser = React.lazy(() => import("./screens/add-user"));
const Profile = React.lazy(() => import("./screens/profile"));
const Cotizaciones = React.lazy(() => import("./screens/cotizaciones"));
const CollectionsScreen = React.lazy(() => import("./screens/database/index"));
const MateriaPrimaScreen = React.lazy(
  () => import("./screens/database/materia-prima")
);
const ScrapsScreen = React.lazy(() => import("./screens/database/scraps"));
const Estandares = React.lazy(() => import("./screens/estandares"));
const EstandarEditor = React.lazy(
  () => import("./screens/estandares/estandar-editor")
);
const EstandaresVersion = React.lazy(
  () => import("./screens/estandares/versiones")
);
const EstandarViewer = React.lazy(
  () => import("./screens/estandares/estandar-viewer")
);
const ProFormas = React.lazy(() => import("./screens/proformas"));
const Clientes = React.lazy(() => import("./screens/database/clientes"));
const EditarCliente = React.lazy(
  () => import("./screens/database/editar-cliente")
);
const ProFormaEditor = React.lazy(
  () => import("./screens/proformas/proforma-editor")
);
const ProformaResumen = React.lazy(
  () => import("./screens/proformas/proforma-resumen")
);
const ProformaImprimir = React.lazy(
  () => import("./screens/proformas/proforma-imprimir")
);
const CotizacionResumen = React.lazy(
  () => import("./screens/cotizaciones/CotizacionResumen")
);
const CotizacionPdf = React.lazy(
  () => import("./screens/aprobada/CotizacionPdf")
);
const AddClient = React.lazy(() => import("./screens/clientes/AddClient"));
const CotizacionCambios = React.lazy(
  () => import("./screens/cotizaciones/CotizacionCambios")
);
const ProformaCambios = React.lazy(
  () => import("./screens/proformas/proforma-cambios")
);
const AnalisisEditor = React.lazy(
  () => import("./screens/analisis/AnalisisEditor")
);
const AnalisisScreen = React.lazy(
  () => import("./screens/analisis/AnalisisScreen")
);

// Icons
const AnalisisSummary = React.lazy(
  () => import("./screens/analisis/AnalisisSummary")
);
const SelladorasScreen = React.lazy(
  () => import("./screens/selladoras/selladoras")
);
const ImpresorasScreen = React.lazy(
  () => import("./screens/impresoras/impresoras")
);
const ZipperScreen = React.lazy(() => import("./screens/zippers/zippers"));
const ValvulaScreen = React.lazy(() => import("./screens/database/valvulas"));
const TalleresScreen = React.lazy(() => import("./screens/talleres/talleres"));
const MaquinaExtrusionScreen = React.lazy(
  () => import("./screens/maquina-extrusion/maquina-extrusion")
);
const ReclamosList = React.lazy(
  () => import("./screens/reclamos/reclamos-list")
);
const ReclamoDetalles = React.lazy(
  () => import("./screens/reclamos/reclamo-detalles")
);
const FichasTecnicasScreen = React.lazy(
  () => import("./screens/fichas-tecnicas/fichas-tecnicas")
);
const EditarFicha = React.lazy(
  () => import("./screens/fichas-tecnicas/editar-ficha")
);
const FichaImprimir = React.lazy(
  () => import("./screens/fichas-tecnicas/ficha-imprimir")
);

interface IRoute {
  path: string;
  exact: boolean;
  main: any;
  nav?: {
    icon: any;
    name: string;
  };
  role: string[];
}

export const GEN_ROUTES: IRoute[] = [
  {
    path: "/",
    exact: true,
    main: Cotizaciones,
    nav: {
      icon: Assignment,
      name: "Cotizaciones",
    },
    role: [
      UserRole.MANAGER,
      UserRole.ADMIN,
      UserRole.EMPLOYEE,
      UserRole.PRODUCTION,
      UserRole.COST,
    ],
  },
  {
    path: "/estandares",
    exact: true,
    main: Estandares,
    nav: {
      icon: Description,
      name: "Estandares",
    },
    role: [
      UserRole.EMPLOYEE,
      UserRole.MANAGER,
      UserRole.ADMIN,
      UserRole.PRODUCTION,
      UserRole.COST,
    ],
  },
  {
    path: "/analisis",
    exact: true,
    main: AnalisisScreen,
    nav: {
      icon: AssessmentIcon,
      name: "Analisis",
    },
    role: [
      UserRole.EMPLOYEE,
      UserRole.MANAGER,
      UserRole.ADMIN,
      UserRole.PRODUCTION,
      UserRole.COST,
    ],
  },
  {
    path: "/analisis/:codigo",
    exact: true,
    main: AnalisisSummary,
    role: [
      UserRole.EMPLOYEE,
      UserRole.MANAGER,
      UserRole.ADMIN,
      UserRole.PRODUCTION,
      UserRole.COST,
    ],
  },
  {
    path: "/analisis/:codigo/:id",
    exact: true,
    main: AnalisisEditor,
    role: [
      UserRole.EMPLOYEE,
      UserRole.MANAGER,
      UserRole.ADMIN,
      UserRole.PRODUCTION,
      UserRole.COST,
    ],
  },
  {
    path: "/estandares/:id/versiones",
    exact: true,
    main: EstandaresVersion,
    role: [
      UserRole.EMPLOYEE,
      UserRole.MANAGER,
      UserRole.ADMIN,
      UserRole.PRODUCTION,
      UserRole.COST,
    ],
  },
  {
    path: "/estandares/:id/versiones/:version",
    exact: true,
    main: EstandarViewer,
    role: [
      UserRole.EMPLOYEE,
      UserRole.MANAGER,
      UserRole.ADMIN,
      UserRole.PRODUCTION,
      UserRole.COST,
    ],
  },
  {
    path: "/estandares/:id",
    exact: true,
    main: EstandarEditor,
    role: [
      UserRole.EMPLOYEE,
      UserRole.MANAGER,
      UserRole.ADMIN,
      UserRole.PRODUCTION,
      UserRole.COST,
    ],
  },
  {
    path: "/proformas",
    exact: true,
    main: ProFormas,
    nav: {
      icon: ReceiptIcon,
      name: "Proformas",
    },
    role: [UserRole.MANAGER, UserRole.ADMIN, UserRole.EMPLOYEE, UserRole.COST],
  },
  {
    path: "/proformas/:id",
    exact: true,
    main: ProFormaEditor,
    role: [UserRole.MANAGER, UserRole.ADMIN, UserRole.EMPLOYEE, UserRole.COST],
  },
  {
    path: "/proformas/:id/resumen",
    exact: true,
    main: ProformaResumen,
    role: [UserRole.MANAGER, UserRole.ADMIN, UserRole.EMPLOYEE, UserRole.COST],
  },
  {
    path: "/proformas/:id/imprimir",
    exact: true,
    main: ProformaImprimir,
    role: [UserRole.MANAGER, UserRole.ADMIN, UserRole.EMPLOYEE, UserRole.COST],
  },
  {
    path: "/proformas/:codigo/cambios",
    exact: true,
    main: ProformaCambios,
    role: [UserRole.MANAGER, UserRole.ADMIN, UserRole.EMPLOYEE],
  },
  {
    path: "/cotizaciones/:id/resumen",
    exact: false,
    main: CotizacionResumen,
    role: [
      UserRole.EMPLOYEE,
      UserRole.MANAGER,
      UserRole.ADMIN,
      UserRole.PRODUCTION,
      UserRole.COST,
    ],
  },
  {
    path: "/cotizaciones/:id/imprimir",
    exact: false,
    main: CotizacionPdf,
    role: [
      UserRole.EMPLOYEE,
      UserRole.MANAGER,
      UserRole.ADMIN,
      UserRole.PRODUCTION,
      UserRole.COST,
    ],
  },
  {
    path: "/cotizaciones/:id/matricula",
    exact: false,
    main: MatriculaPdf,
    role: [
      UserRole.EMPLOYEE,
      UserRole.MANAGER,
      UserRole.ADMIN,
      UserRole.PRODUCTION,
      UserRole.COST,
    ],
  },
  {
    path: "/cotizaciones/:id/cambios",
    exact: false,
    main: CotizacionCambios,
    role: [UserRole.EMPLOYEE, UserRole.MANAGER, UserRole.ADMIN],
  },
  {
    path: "/cotizaciones/:id",
    exact: false,
    main: Cotizador,
    role: [
      UserRole.EMPLOYEE,
      UserRole.MANAGER,
      UserRole.ADMIN,
      UserRole.PRODUCTION,
      UserRole.COST,
    ],
  },
  {
    path: "/fichas-tecnicas",
    exact: true,
    main: FichasTecnicasScreen,
    nav: {
      icon: LibraryBooksIcon,
      name: "Fichas Técnicas",
    },
    role: [
      UserRole.MANAGER,
      UserRole.ADMIN,
      UserRole.EMPLOYEE,
      UserRole.PRODUCTION,
    ],
  },
  {
    path: "/fichas-tecnicas/:codigo",
    exact: true,
    main: EditarFicha,
    role: [
      UserRole.MANAGER,
      UserRole.ADMIN,
      UserRole.EMPLOYEE,
      UserRole.PRODUCTION,
    ],
  },
  {
    path: "/fichas-tecnicas/:codigo/imprimir",
    exact: true,
    main: FichaImprimir,
    role: [
      UserRole.MANAGER,
      UserRole.ADMIN,
      UserRole.EMPLOYEE,
      UserRole.PRODUCTION,
    ],
  },
  {
    path: "/administracion-reclamos",
    exact: true,
    main: ReclamosList,
    nav: {
      icon: LiveHelpIcon,
      name: "Reclamos",
    },
    role: [UserRole.MANAGER, UserRole.ADMIN, UserRole.EMPLOYEE],
  },
  {
    path: "/administracion-reclamos/:codigo",
    exact: true,
    main: ReclamoDetalles,
    role: [UserRole.MANAGER, UserRole.ADMIN, UserRole.EMPLOYEE],
  },
  {
    path: "/perfil",
    exact: true,
    main: Profile,
    role: [
      UserRole.EMPLOYEE,
      UserRole.MANAGER,
      UserRole.ADMIN,
      UserRole.PRODUCTION,
      UserRole.COST,
    ],
  },
  {
    path: "/clientes/agregar",
    exact: true,
    main: AddClient,
    role: [UserRole.EMPLOYEE, UserRole.MANAGER, UserRole.ADMIN],
  },
];

export const ADMIN_ROUTES: IRoute[] = [
  {
    path: "/base-de-datos",
    exact: true,
    main: CollectionsScreen,
    nav: {
      icon: Storage,
      name: "Base de Datos",
    },
    role: [UserRole.ADMIN],
  },
  {
    path: "/base-de-datos/materia-prima",
    exact: true,
    main: MateriaPrimaScreen,
    role: [UserRole.ADMIN],
  },
  {
    path: "/base-de-datos/scraps",
    exact: true,
    main: ScrapsScreen,
    role: [UserRole.ADMIN],
  },
  {
    path: "/base-de-datos/selladoras",
    exact: true,
    main: SelladorasScreen,
    role: [UserRole.ADMIN],
  },
  {
    path: "/base-de-datos/impresoras",
    exact: true,
    main: ImpresorasScreen,
    role: [UserRole.ADMIN],
  },
  {
    path: "/base-de-datos/zippers",
    exact: true,
    main: ZipperScreen,
    role: [UserRole.ADMIN],
  },
  {
    path: "/base-de-datos/valvulas",
    exact: true,
    main: ValvulaScreen,
    role: [UserRole.ADMIN],
  },
  {
    path: "/base-de-datos/clientes",
    exact: true,
    main: Clientes,
    role: [UserRole.ADMIN],
  },
  {
    path: "/base-de-datos/talleres",
    exact: true,
    main: TalleresScreen,
    role: [UserRole.ADMIN],
  },
  {
    path: "/base-de-datos/maquina-extrusion",
    exact: true,
    main: MaquinaExtrusionScreen,
    role: [UserRole.ADMIN],
  },
  {
    path: "/base-de-datos/clientes/:id",
    exact: true,
    main: EditarCliente,
    role: [UserRole.ADMIN],
  },
  {
    path: "/usuarios",
    exact: true,
    main: Users,
    nav: {
      icon: AccountBox,
      name: "Usuarios",
    },
    role: [UserRole.ADMIN],
  },
  {
    path: "/usuarios/agregar",
    exact: true,
    main: AddUser,
    role: [UserRole.ADMIN],
  },
];
