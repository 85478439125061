import { gql } from "apollo-boost";

export const RECLAMO_MUTATIONS = {
    CREATE: gql`
        mutation createReclamo($dto: NewReclamo!) {
            createReclamo(dto: $dto) 
        }
    `,
    UPDATE_RECLAMO: gql`
        mutation updateReclamo($dto: UpdateReclamo!, $codigo: String!) {
            updateReclamo(dto: $dto, codigo: $codigo)
        }
    `,
    CLOSE: gql`
        mutation closeReclamo($codigo: String!) {
            closeReclamo(codigo: $codigo) {
                _id
                first
                last
            }
        }
    `
}