import * as React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, TextField, DialogActions, Button } from '@material-ui/core';
import { SECURITY_MUTATIONS } from '../../graphql/mutations/security.mutations';
import { useMutation } from '@apollo/react-hooks';
import Toast from '../singletons/Toast';

interface Props {
    open: boolean;
    handleClose: any
}

const ForgotPasswordDialog: React.FC<Props> = (props) => {
    const { open, handleClose } = props;

    const [sendResetPassword] = useMutation(SECURITY_MUTATIONS.SEND_RESET_PASSWORD);
    const [email, setEmail] = React.useState('');

    const sendEmail = async () => {
        await sendResetPassword({ variables: { email } });
        handleClose();
        Toast.show({ severity: 'success', text: 'Email enviado' }, 2000);
    }

    return (
        <Dialog open={open} aria-labelledby="form-dialog-title" onClose={handleClose}>
            <DialogTitle id="form-dialog-title">Restablecer Contraseña</DialogTitle>
            <DialogContent>
                <DialogContentText>Te enviaremos un email con las instrucciones para restablecer tu contraseña.</DialogContentText>
                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="Email"
                    type="email"
                    fullWidth
                    value={email}
                    onChange={(e: any) => setEmail(e.target.value)}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">Cerrar</Button>
                <Button onClick={sendEmail} color="primary">Ok</Button>
            </DialogActions>
        </Dialog>
    )
}

export default ForgotPasswordDialog;