import * as React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';

const Alert = (props: AlertProps) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}


let instance: any = null;

interface Component {
  severity: "info" | "success" | "warning" | "error" | undefined,
  text: string
}

class Toast extends React.Component<any, { open: boolean, component: Component, autoHide: number }>  {

  constructor(props: any) {
    super(props);
    instance = this;
    let c: Component = { severity: "info", text: '' };
    this.state = { open: false, component: c, autoHide: 3000 }
  }

  static show = (component: Component, autoHide?: number) => {
    instance.setState({ component, autoHide });
    instance.setOpen(true);
  }

  static hide = () => {
    instance.setOpen(false);
  }

  static showError = (text: string) => Toast.show({ severity: 'error', text }, 3000);
  static showSuccess = (text: string) => Toast.show({ severity: 'success', text }, 3000);

  handleClick = () => this.setOpen(true);

  handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    this.setOpen(false);
  };

  setOpen = (val: boolean) => this.setState({ open: val });

  render() {
    const { open, component, autoHide } = this.state;

    return (
      <div style={{ width: '100%' }} >
        <Snackbar open={open} autoHideDuration={autoHide || 3000} onClose={this.handleClose} >
          <Alert onClose={this.handleClose} severity={component.severity}>
            {component.text}
          </Alert>
        </Snackbar>
      </div>
    );
  }
}

export default Toast;