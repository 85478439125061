import * as React from 'react'
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
    formControl: {
        minWidth: 40,
    },
    gridRow: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        borderBottomWidth: 1,
        borderBottomColor: grey[500],
    },
    normalFontSize: {
        fontSize: '1rem'
    },
    title: {
        color: theme.palette.primary.main,
        marginBottom: theme.spacing(2)
    },
    bold: {
        fontWeight: 'bold',
        paddingRight: 10,
    },
    right: {
        textAlign: 'right',
        whiteSpace: 'pre-wrap'
    }
}));

interface Props {
    left: any,
    right: any
}
const InfoRow: React.FC<Props> = ({ left, right }) => {
    const classes = useStyles();

    return (
        <Box borderBottom={1}>
            <div className={classes.gridRow}>
                <div className={classes.bold}>{left}</div>
                <div className={classes.right}>{right}</div>
            </div>
        </Box>
    )
}


export default InfoRow;