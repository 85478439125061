import { gql } from "apollo-boost";

export const SCRAP_QUERIES = {
    GET: gql`
        query {
            getScraps {
                _id
                tipoDeProducto
                pesos {
                    peso
                    sinBolsa
                    conBolsa
                }
            }
        }
    `
}