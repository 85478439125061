import { gql } from "apollo-boost";

const clientUser = `
_id
first
last
email
role
cellphone
`;

export const embeddedUser = `
    _id
    first
    last
    email
    cellphone
`;

export const USER_QUERIES = {
  GET_LOGGED_IN_USER: gql`
    query {
      getLoggedInUser {
        _id
        first
        last
        email
        role
        cellphone
      }
    }
  `,
  GET_ALL_USERS: gql`
    query {
      getAllUsers {
        _id
        first
        last
        email
        role
      }
    }
  `,
  GET_MANAGERS: gql`
    query {
      getManagers {
        _id
        first
        last
        email
      }
    }
  `,
  GET_USERS_FOR_ESTANDAR: gql`
    query {
      getUsersForEstandar {
        _id
        first
        last
        email
      }
    }
  `,
};
